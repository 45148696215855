import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import {
  CustomDropdown,
  CustomModal, CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import ProductSelection from './ProductSelection';
import { Constant } from '../../utilities';
import { validateRegex } from '../../utilities/FormValidations';
import { getCitySchema, setCitySchema } from '../../utilities/Storage';
import { getHoursDiff } from '../../utilities/Utils';
import { flashSaleCampaign, getCityLocality, storeBulkUpload } from '../../assets/api/axios';
import RemoveStore from './RemoveStore';

function CreateFlashSale(props) {
  const {
    show, onHide, flashSaleId, editFlashSale, handleRequestProcessing,
  } = props;
  let citySchema = getCitySchema();
  if (citySchema) {
    const hrDiff = getHoursDiff(citySchema.lastModifiedTime, new Date());
    if (hrDiff > 24) {
      citySchema = null;
    }
  }
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [chooseProductModal, setChooseProductModal] = useState(false);
  const [city, setCity] = useState(null);
  const [locality, setLocality] = useState(null);
  const [pinCode, setPinCode] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storesCsvFile, setStoresCsvFile] = useState('');
  const [storeList, setStoreList] = useState([]);
  const [option, setOption] = useState('input');
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTill, setValidTill] = useState(new Date());
  const [discountAmount, setDiscountAmount] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [cityLocality, setCityLocality] = useState(citySchema?.cities || null);
  const [loadStatus, setLoadStatus] = useState('error');
  const [removeStoreId, setRemoveStoreId] = useState('');
  const [active, setActive] = useState('');
  const [flashSaleTarget, setFlashSaleTarget] = useState('store');
  const [flashSaleTitle, setFlashSaleTitle] = useState('');

  const hiddenFileInput = React.createRef();

  const disabled = submitting || !selectedProduct
  || (!storeId && !storesCsvFile && storeList.length === 0
    && !city && !locality && (!pinCode || pinCode?.length !== 6))
  || !discountAmount || !validFrom || !validTill || !flashSaleTitle;

  const activeOptions = {
    key: 'active',
    displayText: '',
    options: [
      {
        label: 'True',
        value: 'true',
      },
      {
        label: 'False',
        value: 'false',
      },
    ],
  };

  const onClose = () => {
    setSelectedProduct(null);
    setChooseProductModal(false);
    setCity(null);
    setLocality(null);
    setPinCode('');
    setStoreId('');
    setStoresCsvFile('');
    setOption('input');
    setValidFrom(new Date());
    setValidTill(new Date());
    setDiscountAmount('');
    setFlashSaleTitle('');
    setSubmitting(false);
    setSubmitError('');
    setLoadStatus('');
    onHide();
    handleRequestProcessing();
  };

  const handleCsvUpload = (retry = 3, id) => {
    let data = {};
    const formData = new FormData();
    formData.append('csv', storesCsvFile);
    formData.append('flashSaleCampaignId', id);
    data = formData;
    storeBulkUpload(
      'POST',
      data,
    ).then(() => {
      onClose();
    }).catch(() => {
      if (retry > 1) {
        handleCsvUpload(retry - 1, id);
        return;
      }
      onClose();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validFrom >= validTill) {
      setSubmitError('Please Check!! Start Date is less than End Date');
      return;
    }
    const stDate = `${moment(validFrom).format('YYYY-MM-DDTHH:mm')}`;
    const eDate = `${moment(validTill).format('YYYY-MM-DDTHH:mm')}`;
    let data = {};
    if (editFlashSale) {
      data = {
        flashSaleCampaignId: flashSaleId,
        isActive: active,
      };
    } else {
      data = {
        productId: selectedProduct?.productId,
      };
    }
    if (option === 'input' && storeId) {
      data = {
        ...data,
        storeId: parseInt(storeId, 10),
      };
    }
    if (pinCode) {
      data = {
        ...data,
        pinCode: parseInt(pinCode, 10),
      };
    }
    setSubmitting(true);
    setSubmitError('');
    flashSaleCampaign(
      editFlashSale ? 'PATCH' : 'POST',
      {
        ...data,
        discount: discountAmount,
        flashSaleTitle,
        city: city?.value,
        area: locality?.value,
        startDate: ((new Date(stDate).getTime()) / 1000),
        endDate: ((new Date(eDate).getTime()) / 1000),
        flashSaleLevel: flashSaleTarget,
      },
    ).then((res) => {
      if (res.status === 200) {
        if (option === 'csv') {
          handleCsvUpload(3, res?.data?.data?.flashSaleCampaignId);
          return;
        }
        onClose();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
    });
  };

  const handleRadioBtn = (e) => {
    setOption(e.target.id);
    setStoreId();
    setStoresCsvFile('');
  };

  const handleTarget = (e) => {
    setFlashSaleTarget(e.target.id);
    setCity(null);
    setLocality(null);
    setPinCode('');
    setStoreId('');
    setStoresCsvFile('');
    setOption('input');
  };

  const loadCityLocality = () => {
    if (!cityLocality) {
      getCityLocality(
        'GET',
      ).then((res) => {
        setCityLocality(res.data.data);
        setCitySchema({
          lastModifiedTime: new Date(),
          cities: res.data.data,
        });
      }).catch(() => {});
    }
  };

  const handleLoad = () => {
    setLoadStatus('loading');
    flashSaleCampaign(
      'GET',
      null,
      {
        flashSaleCampaignId: flashSaleId,
      },
    ).then((res) => {
      const data = res?.data?.data;
      setSelectedProduct({
        productName: data.productName,
        productId: data.libraryProductId,
        mrp: data.mrp,
      });
      if (data?.city) {
        setCity({ label: data.city, value: data.city });
      }
      if (data?.area) {
        setLocality({ label: data.area, value: data.area });
      }
      if (data?.pinCode) {
        setPinCode(data?.pinCode);
      }
      setValidFrom(data?.startDate);
      setValidTill(data?.endDate);
      setDiscountAmount(data?.discount);
      setStoreList(data?.storeList);
      setActive(data?.isActive);
      setFlashSaleTarget(data?.flashSaleLevel);
      setFlashSaleTitle(data?.flashSaleTitle);
      setLoadStatus('');
    }).catch(() => {
      setLoadStatus('error');
    });
  };

  useEffect(() => {
    loadCityLocality();
    if (editFlashSale) {
      handleLoad();
    }
    // eslint-disable-next-line
  }, []);

  const body = (
    <Container className="px-4 py-4">
      <Row>
        {(() => {
          let showcase = null;
          if (editFlashSale && (loadStatus === 'loading')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              </Col>
            );
          } else if (editFlashSale && (loadStatus === 'error')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              </Col>
            );
          } else {
            showcase = (
              <Col>
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Form.Group as={Row}>
                    <Form.Label column sm={6} className="fs-1">
                      <b>Product Name / Id</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Button
                        variant=""
                        className={`pl-0 fs-01 font-weight-bold bg-white ${selectedProduct ? 'text-success' : 'text-primary'}`}
                        onClick={() => {
                          setChooseProductModal(true);
                        }}
                        disabled={editFlashSale}
                      >
                        {`${selectedProduct
                          ? `${selectedProduct?.productId} - ${selectedProduct?.productName}`
                          : '+ CHOOSE PRODUCT'}`}
                      </Button>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="d-flex align-items-center"
                  >
                    <Form.Label column sm={6} className="fs-1">
                      <b>Flash Sale Level</b>
                    </Form.Label>
                    <Col sm={12}>
                      <Row>
                        <Col className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="store"
                            checked={flashSaleTarget === 'store'}
                            onChange={handleTarget}
                          />
                          <span className="fs-01 pl-1">Store</span>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="city"
                            checked={flashSaleTarget === 'city'}
                            onChange={handleTarget}
                          />
                          <span className="fs-01 pl-1">City</span>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="locality"
                            checked={flashSaleTarget === 'locality'}
                            onChange={handleTarget}
                          />
                          <span className="fs-01 pl-1">Locality</span>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="pincode"
                            checked={flashSaleTarget === 'pincode'}
                            onChange={handleTarget}
                          />
                          <span className="fs-01 pl-1">Pincode</span>
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  {
                    cityLocality !== null && (
                      <>
                        {
                          flashSaleTarget === 'city' && (
                            <Form.Group as={Row}>
                              <Form.Label column sm={6} className="fs-1">
                                <b>City</b>
                              </Form.Label>
                              <Col
                                sm={9}
                              >
                                <Select
                                  id="city"
                                  onChange={(selectedCity) => {
                                    setCity(selectedCity);
                                  }}
                                  value={city}
                                  options={Object.values(cityLocality?.cities)
                                    .map((item) => ({ label: item, value: item }))}
                                  className="fs-01"
                                />
                              </Col>
                            </Form.Group>
                          )
                        }
                        {
                          flashSaleTarget === 'locality' && (
                            <Form.Group as={Row}>
                              <Form.Label column sm={6} className="fs-1">
                                <b>Locality</b>
                              </Form.Label>
                              <Col
                                sm={9}
                              >
                                <Select
                                  id="locality"
                                  onChange={(selectedLocality) => {
                                    setLocality(selectedLocality);
                                  }}
                                  value={locality}
                                  options={Object.values(cityLocality?.localities)
                                    .map((item) => ({ label: item, value: item }))}
                                  className="fs-01"
                                />
                              </Col>
                            </Form.Group>
                          )
                        }
                      </>
                    )
                  }
                  {
                    flashSaleTarget === 'pincode' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>Pincode</b>
                        </Form.Label>
                        <Col sm={9}>
                          <input
                            className="form-control fs-01"
                            type="text"
                            placeholder="Enter Pincode"
                            value={pinCode}
                            onChange={(e) => {
                              if (validateRegex('integerValue', e.target.value)) {
                                setPinCode(e.target.value);
                              }
                            }}
                            maxLength="6"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Start Date</b>
                    </Form.Label>
                    <Col
                      sm={9}
                    >
                      <input
                        type="datetime-local"
                        className="form-control fs-01"
                        value={moment(validFrom).format('YYYY-MM-DDTHH:mm')}
                        id="validFrom"
                        onChange={(e) => setValidFrom(new Date(e.target.value))}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>End Date</b>
                    </Form.Label>
                    <Col
                      sm={9}
                    >
                      <input
                        type="datetime-local"
                        className="form-control fs-01"
                        value={moment(validTill).format('YYYY-MM-DDTHH:mm')}
                        id="validTill"
                        onChange={(e) => setValidTill(new Date(e.target.value))}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>
                  {
                    editFlashSale && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Active</b>
                        </Form.Label>
                        <Col sm={9}>
                          <CustomDropdown
                            item={activeOptions}
                            name="active"
                            onChange={(item) => setActive(item.active)}
                            selectedVal={active.toString()}
                            closeButton={false}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    selectedProduct && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>MRP</b>
                        </Form.Label>
                        <Col
                          sm={9}
                          className="fs-01 text-success font-weight-bold"
                        >
                          {selectedProduct?.mrp}
                        </Col>
                      </Form.Group>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Discount Amount</b>
                    </Form.Label>
                    <Col sm={9}>
                      <input
                        type="number"
                        placeholder="Enter Flash Sale Amount"
                        value={discountAmount}
                        id="discountAmount"
                        onChange={(e) => {
                          setDiscountAmount(e.target.value);
                        }}
                        autoComplete="off"
                        className="form-control fs-01"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Flash Sale Title</b>
                    </Form.Label>
                    <Col sm={9}>
                      <input
                        type="text"
                        placeholder="Enter Flash Sale Title"
                        value={flashSaleTitle}
                        id="flashSaleTitle"
                        onChange={(e) => {
                          setFlashSaleTitle(e.target.value);
                        }}
                        autoComplete="off"
                        className="form-control fs-01"
                      />
                    </Col>
                  </Form.Group>
                  {
                    flashSaleTarget === 'store' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>Store</b>
                        </Form.Label>
                        <Col sm={`${option === 'input' ? 9 : 12}`}>
                          <Row>
                            <Col className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="input"
                                checked={option === 'input'}
                                onChange={handleRadioBtn}
                              />
                              <span className="fs-01 pl-1">Single</span>
                            </Col>
                            <Col className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="csv"
                                checked={option === 'csv'}
                                onChange={handleRadioBtn}
                              />
                              <span className="fs-01 pl-1">Multiple</span>
                            </Col>
                          </Row>
                          <Row className="py-1">
                            <Col>
                              {
                                option === 'input' ? (
                                  <div>
                                    <input
                                      type="number"
                                      placeholder="Enter Store Id"
                                      value={storeId}
                                      id="storeId"
                                      onChange={(e) => setStoreId(e.target.value)}
                                      autoComplete="off"
                                      className="form-control fs-01"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <Row>
                                      <Col>
                                        <input
                                          type="file"
                                          className="d-none"
                                          accept=".xls,.xlsx,.csv"
                                          ref={hiddenFileInput}
                                          onChange={(e) => {
                                            setStoresCsvFile(e.target.files[0]);
                                          }}
                                        />
                                        <Button
                                          variant="primary"
                                          className="fs-01 font-weight-bold"
                                          onClick={() => {
                                            hiddenFileInput.current.click();
                                          }}
                                        >
                                          UPLOAD CSV
                                        </Button>
                                      </Col>
                                      <Col xs="auto" className="d-flex align-items-center justify-content-end fs-01">
                                        <b>Sample CSV</b>
                                        <Button
                                          variant="link"
                                          className="p-0 fs-01"
                                          onClick={() => {
                                            window.open(cityLocality?.sampleCsv, '_blank');
                                          }}
                                        >
                                          <Svg
                                            svg="file"
                                            width="1.3rem"
                                            fill={Constant.Color.PRIMARY}
                                          />
                                        </Button>
                                      </Col>
                                    </Row>
                                    <span className="fs-01 text-success pt-1">{storesCsvFile?.name}</span>
                                  </>
                                )
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    editFlashSale && storeList.length > 0 && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} />
                        <Col sm={15} className="overflow-y-scroll maxh-220p">
                          <CustomTable
                            headers={[
                              {
                                key: 'storeId',
                                displayText: 'StoreId',
                              },
                              {
                                key: 'name',
                                displayText: 'Name',
                              },
                              {
                                key: 'action',
                                displayText: 'Action',
                                renderer: (storeData) => (
                                  <Button
                                    onClick={() => {
                                      setRemoveStoreId(storeData.storeId);
                                    }}
                                    className="p-1 fs-01"
                                  >
                                    Remove
                                  </Button>
                                ),
                              },
                            ]}
                            content={storeList}
                            keyField="storeId"
                            totalItems={storeList.length}
                            isPaginated={false}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    submitting && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    submitError && (
                      <div
                        className="d-flex align-item-center justify-content-center fs-0 py-2"
                      >
                        <Col sm={6} />
                        <span className="text-danger">
                          {submitError}
                        </span>
                      </div>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6} />
                    <Col>
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        disabled={disabled}
                        className="fs-01"
                      >
                        {editFlashSale ? 'SAVE CHANGES' : 'CREATE'}
                      </Button>
                    </Col>
                  </Form.Group>

                </Form>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <>
      <ProductSelection
        show={chooseProductModal}
        onHide={() => setChooseProductModal(false)}
        handleSelectedProduct={(product) => setSelectedProduct(product)}
      />
      {
        !!removeStoreId && (
          <RemoveStore
            show={!!removeStoreId}
            onHide={() => setRemoveStoreId('')}
            storeId={removeStoreId}
            handleRequest={handleLoad}
            flashSaleCampaignId={flashSaleId}
          />
        )
      }
      <CustomModal
        show={show}
        title={`${editFlashSale ? 'Edit' : 'Create'} Flash Sale`}
        onHide={() => onHide()}
        size="lg"
        body={body}
        closeButton
      />
    </>
  );
}

CreateFlashSale.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  flashSaleId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  editFlashSale: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default CreateFlashSale;
