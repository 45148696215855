import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Cluster from './Cluster';
import DeliveryInfo from './DeliveryInfo';
import { deliveryHexes } from '../../../assets/api/axios';
import ConfirmationModal from './ConfirmationModal';

const mapUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q&libraries=drawing,places&country=IN';

function DeliveryMap(props) {
  const {
    storeCode, storeLocation, updateHexRadius,
    updatedDeliveryRadius, deliveryRadii, validateDeliveryRadius,
  } = props;
  const [deliveryHexList, setDeliveryHexList] = useState([]);
  const [selectedHexes, setSelectedHexes] = useState([]);
  const [modalView, setModalView] = useState('VIEW');
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedHex, setSelectedHex] = useState(null);

  useEffect(() => {
    deliveryHexes(
      'GET',
      storeCode,
      {},
      {
        updatedDeliveryRadius: updatedDeliveryRadius * 1000,
      },
    ).then((res) => {
      setDeliveryHexList([...res?.data?.data]);
      setSelectedHexes([...res?.data?.data.filter((hex) => hex.isSelected).map((h) => h.hexId)]);
    }).catch(() => {
    });
  }, [updateHexRadius, updatedDeliveryRadius]);

  const handleViewChange = (view) => {
    setModalView(view);
  };

  const updateHexes = (hex, isSelected) => {
    const hexIndex = deliveryHexList?.map((hexB) => hexB.hexId).indexOf(hex?.hexId);
    const updateHexBoundaries = deliveryHexList;
    if (hexIndex !== -1) {
      updateHexBoundaries[hexIndex].isSelected = isSelected;
      setDeliveryHexList([
        ...updateHexBoundaries,
      ]);
    }
  };

  const handleHex = (hex, isSelected) => {
    if (isSelected) {
      if (!hex?.insideRadius) {
        setSelectedHex(hex);
        setConfirmModal(true);
        return;
      }
      setSelectedHexes([...selectedHexes, hex?.hexId]);
    } else {
      const updatedHexes = selectedHexes;
      const index = selectedHexes?.map((id) => id).indexOf(hex?.hexId);
      if (index !== -1) {
        updatedHexes.splice(index, 1);
      }
      setSelectedHexes(updatedHexes);
    }
    updateHexes(hex, isSelected);
  };

  return (
    <>
      {
        confirmModal && selectedHex && (
          <ConfirmationModal
            show={confirmModal}
            onHide={() => setConfirmModal(false)}
            distance={selectedHex?.distance}
            onConfirm={() => {
              setSelectedHexes([...selectedHexes, selectedHex?.hexId]);
              updateHexes(selectedHex, true);
              setConfirmModal(false);
              setSelectedHex(null);
            }}
          />
        )
      }
      <Row className="d-flex">
        <Col
          xs={15}
          className="mr-5"
        >
          <Cluster
            googleMapURL={mapUrl}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            storeCode={storeCode}
            storeLocation={storeLocation}
            deliveryHexList={deliveryHexList}
            modalView={modalView}
            handleHex={handleHex}
          />
        </Col>
        <Col>
          <DeliveryInfo
            storeCode={storeCode}
            deliveryHexList={[deliveryHexList]}
            modalView={modalView}
            handleViewChange={handleViewChange}
            selectedHexes={selectedHexes}
            deliveryRadii={deliveryRadii}
            validateDeliveryRadius={validateDeliveryRadius}
          />
        </Col>
      </Row>
    </>
  );
}

DeliveryMap.propTypes = {
  storeCode: PropTypes.string.isRequired,
  storeLocation: PropTypes.shape({}).isRequired,
  updateHexRadius: PropTypes.bool.isRequired,
  updatedDeliveryRadius: PropTypes.number.isRequired,
  deliveryRadii: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  validateDeliveryRadius: PropTypes.func.isRequired,
};

export default DeliveryMap;
