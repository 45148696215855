import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import {
  exotelCallInit, rerouteableStore, rerouteOrder as rerouteOrderApi, getBillDetails,
} from '../../../assets/api/axios';
import { CustomModal, CustomTable } from '../../../component/common';
import ConfirmProducts from './reroute-order/ConfirmProducts';
import ProductMatches from './reroute-order/ProductMatches';
import { getDateTime } from '../../../utilities/Utils';
import { Storage } from '../../../utilities';

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const finalDispositions = [
  {
    label: 'CNR-Need to confirm with customer-product and pricing changes',
    value: 'CNR-Need to confirm with customer-Product and pricing changes',
  },
  {
    label: 'Customer is ready to wait',
    value: 'Customer is ready to wait',
  },
  {
    label: 'Customer will order again cancelled',
    value: 'Customer will order again cancelled',
  },
  {
    label: 'No shop to arrange same product category',
    value: 'No shop to arrange same product-category',
  },
  {
    label: 'Pricing changes customer denied',
    value: 'Pricing changes- customer denied',
  },
  {
    label: 'Order Cancelled as per customer request',
    value: 'Order Cancelled as per customer request',
  },
  {
    label: 'Customer will place order by himself',
    value: 'Customer will place order by himself',
  },
  {
    label: 'Waiting for address update from customer side',
    value: 'Waiting for address update from customer side',
  },
  {
    label: 'Retailer will accept the order',
    value: 'Retailer will accept the order',
  },
  {
    label: 'Coupon related issue',
    value: 'Coupon related Issue',
  },
  {
    label: 'Customer not ready to wait',
    value: 'Customer not ready to wait',
  },
  {
    label: 'Product no longer needed',
    value: 'Product no longer needed',
  },
  {
    label: 'Red/White/Yellow retailer not responding',
    value: 'Red/White/Yellow Retailer not responding',
  },
  {
    label: 'Store far from customer location',
    value: 'Store Far from customer location',
  },
  {
    label: 'Store not available at customer location',
    value: 'Store not available at customer location',
  },
  {
    label: 'Delivered',
    value: 'Delivered',
  },
  {
    label: 'Order accepted by retailer',
    value: 'Order accepted by retailer',
  },
  {
    label: 'Customer placed the order by mistake',
    value: 'Customer placed the Order By mistake',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const shopWiseDispositions = [
  {
    label: 'Retailer not responding',
    value: 'Retailer not responding',
  },
  {
    label: 'Product not available',
    value: 'Product not available',
  },
  {
    label: 'Price difference',
    value: 'Price Difference',
  },
  {
    label: 'Order diverted',
    value: 'Order diverted',
  },
  {
    label: 'Shop closed permanently/ temporary',
    value: 'Shop Closed Permanently/ Temporary',
  },
  {
    label: 'Busy/ Disconnected/ Call back',
    value: 'Busy/ Disconnected/ Call back',
  },
  {
    label: 'Alternate product available',
    value: 'Alternate Product available',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

class RerouteOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'STORE',
      stores: null,
      selectedStore: null,
      confirmedProducts: null,
      selectedTagColor: '',
      rerouteOrderError: false,
      rerouting: false,
      orderConfirm: false,
      searchText: '',
      selectedStoreId: '',
      updateStoreErrorMsg: '',
      performingAction: '',
      otherComment: '',
      otherCommentModal: false,
      otherCommentId: '',
      callInitiateStatus: '',
      selectedBde: '',
      selectedPrimaryNo: '',
      selectedSecondaryNo: '',
      prevCustomProducts: [],
      newProducts: [],
      customProducts: [],
    };
    this.retryCall = () => {};
  }

  alterTab = (tab) => {
    this.setState({
      tab,
    });
  }

  loadStores = () => {
    this.alterTab('LOADING');
    const { selectedTagColor, searchText } = this.state;
    const { orderId } = this.props;
    rerouteableStore(
      'GET',
      orderId,
      {
        color: selectedTagColor,
        searchText,
      },
    ).then((res) => {
      this.setState({
        stores: res.data,
        tab: 'STORE',
        searchText,
      });
    }).catch(() => {
      this.retryCall = () => { this.loadStores(); };
      this.alterTab('ERROR');
    });
  };

  componentDidMount = () => {
    this.loadStores();
  }

  onConfirmProducts = (products, customProducts, newProducts, prevCustomProducts) => {
    this.setState({
      confirmedProducts: [...customProducts, ...products],
      customProducts,
      newProducts,
      prevCustomProducts,
      tab: 'CONFIRMATION',
    });
  };

  getBillDetails = (confirmedProducts, customProducts, newProducts) => {
    const { selectedStore } = this.state;
    const { orderId } = this.props;
    let productsData = confirmedProducts.map((i) => ({
      retailerProductId: i.RetailerProductId,
      quantity: i.quantity,
      sellingPrice: i.sellingPrice,
    }));

    customProducts.forEach((customProduct) => {
      if (customProduct.suggestions && customProduct.suggestions.length > 0) {
        customProduct.suggestions.forEach((suggestion) => {
          if (suggestion.isMatched) {
            productsData.push({
              retailerProductId: suggestion.RetailerProductId,
              quantity: suggestion.quantity,
              sellingPrice: suggestion.sellingPrice,
              isCustomProduct: true,
            });
          }
        });
      }
    });

    productsData = [
      ...productsData,
      ...newProducts.map((i) => ({
        retailerProductId: i.RetailerProductId,
        quantity: i.quantity,
        sellingPrice: i.sellingPrice,
      })),
    ];
    this.setState({
      rerouteOrderError: false,
      rerouting: true,
    });
    getBillDetails(
      'PATCH',
      {
        storeId: selectedStore.storeId,
        productsData,
      },
      orderId,
    ).then((res) => {
      this.setState({
        rerouting: false,
        orderConfirm: true,
        orderConfirmData: res.data.data,
      });
    }).catch(() => {
      this.setState({
        rerouteOrderError: true,
        rerouting: false,
      });
      setTimeout(() => {
        this.setState({
          rerouteOrderError: false,
        });
      }, 5000);
    });
  };

  rerouteOrder = (confirmedProducts, customProducts, newProducts) => {
    const { selectedStore } = this.state;
    const { orderId, onSuccessfulReroute } = this.props;
    let productsData = confirmedProducts.map((i) => ({
      retailerProductId: i.RetailerProductId,
      quantity: i.quantity,
      sellingPrice: i.sellingPrice,
    }));
    customProducts.forEach((customProduct) => {
      if (customProduct.suggestions && customProduct.suggestions.length > 0) {
        customProduct.suggestions.forEach((suggestion) => {
          if (suggestion.isMatched) {
            productsData.push({
              retailerProductId: suggestion.RetailerProductId,
              quantity: suggestion.quantity,
              sellingPrice: suggestion.sellingPrice,
              isCustomProduct: true,
              id: customProduct.customProduct.customOrderProductId,
            });
          }
        });
      }
    });
    const customProductData = [];
    customProducts.forEach((item) => {
      if (item.suggestions && item.suggestions.length > 0) {
        item.suggestions.forEach((suggestion) => {
          if (suggestion.isMatched) {
            customProductData.push({
              id: item.customProduct.customOrderProductId,
              name: item.customProduct.name,
              imageUrl: item.customProduct.imageUrl,
              quantity: suggestion.quantity,
              sellingPrice: suggestion.sellingPrice,
              unit: item.customProduct.unit,
              uom: item.customProduct.uom,
            });
          }
        });
      }
    });

    productsData = [
      ...productsData,
      ...newProducts.map((i) => ({
        retailerProductId: i.RetailerProductId,
        quantity: i.quantity,
        sellingPrice: i.sellingPrice,
      })),
    ];
    this.setState({
      rerouteOrderError: false,
      rerouting: true,
    });
    rerouteOrderApi(
      'POST',
      {
        storeId: selectedStore.storeId,
        productsData,
        customProductData,
      },
      orderId,
    ).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          rerouting: false,
        });
        onSuccessfulReroute(res.data.data);
      } else if (res && res.status === 209) {
        this.setState({
          rerouting: false,
          orderConfirm: true,
          orderConfirmData: res.data,
        });
      }
    }).catch(() => {
      this.setState({
        rerouteOrderError: true,
        rerouting: false,
      });
      setTimeout(() => {
        this.setState({
          rerouteOrderError: false,
        });
      }, 5000);
    });
  };

  onSelectStore = (data) => {
    this.setState({
      tab: 'PRODUCTS',
      selectedStore: data,
    });
  }

  changeFilter = (e) => {
    const { checked, name } = e.target;
    this.setState({
      [name]: checked,
    }, () => this.loadStores());
  }

  changeColor = (color) => {
    this.setState({
      selectedTagColor: color,
    }, () => this.loadStores());
  }

  getSameCatStores = (isSame) => {
    const { stores } = this.state;
    const { orderStoreType } = this.props;
    if (isSame) {
      return stores.data.filter((store) => store.storeType === orderStoreType);
    }
    return stores.data.filter((store) => store.storeType !== orderStoreType);
  }

  updateStoreData = (field, value) => {
    const { selectedStoreId, stores, otherComment } = this.state;
    const storeIndex = stores.data.findIndex((store) => store.storeId === selectedStoreId);
    if (stores.data[storeIndex].storeId === selectedStoreId) {
      const updateStores = stores.data;
      updateStores[storeIndex][field] = otherComment ? 'other' : value;
      this.setState({
        selectedStoreId: '',
        otherComment: '',
        otherCommentId: '',
        stores: {
          ...stores,
          data: updateStores,
        },
      });
    }
  }

  patchStore = (storeId, data, action) => {
    const { orderId } = this.props;
    rerouteableStore(
      'PATCH',
      orderId,
      {},
      {
        storeId: parseInt(storeId, 10),
        dispositionComment: data,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          performingAction: '',
          otherCommentModal: false,
        });
        this.updateStoreData(action, data);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        updateStoreErrorMsg: errorMsg,
        otherCommentModal: false,
      });
    });

    this.retryPatchStore = () => {
      this.setState({
        updateStoreErrorMsg: '',
        otherCommentModal: false,
      });
      this.patchStore(storeId, data, action);
    };
  }

  patchStoreDetails = async (value, storeDetails, action) => {
    this.setState({
      selectedStoreId: storeDetails.storeId,
      performingAction: action,
    }, () => {
      this.patchStore(storeDetails.storeId, value, action);
    });
  }

  updateFinalDisposition = (value) => {
    const { stores, otherComment } = this.state;
    const updateDisposition = stores;
    updateDisposition.finalDisposition = otherComment ? 'other' : value;
    this.setState({
      performingAction: '',
      otherCommentModal: false,
      otherComment: '',
      stores: {
        ...stores,
        updateDisposition,
      },
    });
  }

  addFinalDisposition = (value, action) => {
    const { orderId } = this.props;
    const { selectedStore } = this.state;
    this.setState({
      performingAction: action,
    });
    rerouteableStore(
      'PATCH',
      orderId,
      {},
      {
        storeId: selectedStore.storeId,
        dispositionComment: value,
      },
    ).then(() => {
      this.updateFinalDisposition(value);
    }).catch(() => {
      this.setState({
        performingAction: '',
        otherCommentModal: false,
        updateStoreErrorMsg: 'Oops Something Went Wrong!! Please Try Again!!',
      });
    });

    this.retryFinalDisposition = () => {
      this.setState({
        updateStoreErrorMsg: '',
        otherCommentModal: false,
      });
      this.addFinalDisposition(value, action);
    };
  }

  initiateCall = (phoneNumber, storeCode, field) => {
    this.setState({
      [field]: storeCode,
      callInitiateStatus: 'loading',
    });
    const exotelCallMeta = {
      callFromUser: {
        adminUser: Storage.getUserName(),
        phoneNumber: Storage.getUserNumber(),
      },
      callToUser: {
        phoneNumber,
      },
    };
    exotelCallInit(
      'POST',
      exotelCallMeta,
    ).then(() => {
      this.setState({
        callInitiateStatus: 'success',
      });
      setTimeout(() => {
        this.setState({
          callInitiateStatus: '',
          [field]: '',
        });
      }, 5000);
    }).catch(() => {
      this.setState({
        callInitiateStatus: 'error',
        [field]: '',
      });
    });
  }

  render() {
    const { show, orderId, onCancel } = this.props;
    const {
      stores, rerouteOrderError, tab,
      selectedStore, confirmedProducts, rerouting,
      orderConfirm, orderConfirmData, searchText,
      performingAction, selectedStoreId, updateStoreErrorMsg,
      otherCommentModal, otherComment, otherCommentId, callInitiateStatus, selectedBde,
      selectedPrimaryNo, selectedSecondaryNo, prevCustomProducts, customProducts, newProducts,
    } = this.state;

    const storesConfig = [
      {
        key: 'selectStore',
        displayText: 'Select',
        renderer: (data) => (
          <input
            type="radio"
            checked={selectedStore && selectedStore.storeId === data.storeId}
            onChange={() => { this.onSelectStore(data); }}
          />
        ),
      },
      {
        key: 'tag',
        displayText: 'Tag',
        renderer: (data) => (
          <div
            style={{
              height: '25px',
              width: '50px',
              backgroundColor: data.tag,
              border: '1px solid',
            }}
          />
        ),
      },
      {
        key: 'storeId',
        displayText: 'Store Code',
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
        renderer: (data) => (
          <Row className="mx-0 align-items-center min-w100">
            <Col xs="16" className="p-0">
              {data.storeName}
            </Col>
            <Col xs="8" className="p-0">
              {
                data.ispremiumShop && (
                  <div
                    className="store-image-container"
                  >
                    <img
                      src="/images/premium.png"
                      className="product-image"
                      alt=""
                    />
                  </div>
                )
              }
            </Col>
          </Row>
        ),
      },
      {
        key: 'storeType',
        displayText: 'Store Type',
      },
      {
        key: 'phoneNumber',
        displayText: 'Phone Number',
        renderer: (data) => (
          data.phoneNumber ? (
            <>
              <Button
                variant="link"
                className="p-0 fs-01"
                onClick={() => {
                  this.initiateCall(data.phoneNumber, data.storeId, 'selectedPrimaryNo');
                }}
                disabled={callInitiateStatus === 'loading'}
              >
                {data.phoneNumber}
              </Button>
              {
                callInitiateStatus === 'loading' && selectedPrimaryNo === data.storeId && (
                  <div className="mx-1">
                    <Spinner variant="primary" size="sm" animation="border" />
                  </div>
                )
              }
              {
                callInitiateStatus === 'success' && selectedPrimaryNo === data.storeId && (
                  <div className="text-success">Call Initiate</div>
                )
              }
              {
                callInitiateStatus === 'error' && selectedPrimaryNo === data.storeId && (
                  <div className="text-danger">Oops Retry</div>
                )
              }
            </>
          ) : '--'
        ),
      },
      {
        key: 'isFraud',
        displayText: 'Is Fraud',
        renderer: (data) => (
          <span className={`${data.isFraud ? 'text-danger' : 'text-green'}`}>
            {data.isFraud ? 'YES' : 'NO'}
          </span>
        ),
      },
      {
        key: 'productMatch',
        displayText: 'Product Match(%)',
      },
      {
        key: 'pickupOnly',
        displayText: 'Pick Up Only',
        renderer: (data) => renderStatus(data.pickupOnly),
      },
      {
        key: 'distance',
        displayText: 'Distance from customer',
        renderer: (data) => (
          <>
            {(data.distance / 1000).toFixed(2)}
            &nbsp;
            km
          </>
        ),
      },
      {
        key: 'disposition',
        displayText: 'Disposition',
        renderer: (data) => {
          if (
            performingAction === 'disposition'
            && selectedStoreId === data.storeId
            && !updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value === 'other') {
                  this.setState({
                    otherCommentModal: true,
                    otherCommentId: data,
                  });
                  return;
                }
                if (e.target.value !== '') {
                  this.patchStoreDetails(e.target.value, data, 'disposition');
                }
              }}
              value={data.disposition}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="">Select</option>
              {shopWiseDispositions.map((disposition) => (
                <option value={disposition.value} key={disposition.value}>
                  {disposition.label}
                </option>
              ))}
            </select>
          );
        },
      },
      {
        key: 'deliveryCharge',
        displayText: 'Delivery Charge',
      },
      {
        key: 'homeDeliveryMov6',
        displayText: 'HD Min. Order Value',
      },
      {
        key: 'movPenaltyAmount',
        displayText: 'MOV Penalty Amt.',
      },
      {
        key: 'eligibleForCoupon',
        displayText: 'Coupon Eligible',
        renderer: (data) => renderStatus(data.eligibleForCoupon),
      },
      {
        key: 'lastOrderDeliveryOn',
        displayText: 'Last Order Delivered On',
        renderer: (data) => getDateTime(data.lastOrderDeliveryOn),
      },
      {
        key: 'HDOrderDeliveryRate',
        displayText: 'HD Order Delivery Rate',
      },
      {
        key: 'HDOrderDeliveryCount',
        displayText: 'HD Order Delivery Count',
      },
      {
        key: 'medianAcceptanceTime',
        displayText: 'HD Order Median Acceptance Time',
      },
      {
        key: 'medianDeliveryTime',
        displayText: 'HD Order Median Delivery Time',
      },
      {
        key: 'retailerConnectivityDate',
        displayText: 'Last Retailer Connectivity Date',
        renderer: (data) => getDateTime(data.retailerConnectivityDate),
      },
      {
        key: 'lastDeliveredDate',
        displayText: 'Latest Delivered Date',
        renderer: (data) => getDateTime(data.lastDeliveredDate),
      },
      {
        key: 'alternatePhoneNumber',
        displayText: 'Alternate Phone Number',
        renderer: (data) => (
          data.alternatePhoneNumber ? (
            <>
              <Button
                variant="link"
                className="p-0 fs-01"
                onClick={() => {
                  this.initiateCall(data.alternatePhoneNumber, data.storeId, 'selectedSecondaryNo');
                }}
                disabled={callInitiateStatus === 'loading'}
              >
                {data.alternatePhoneNumber}
              </Button>
              {
                callInitiateStatus === 'loading' && selectedSecondaryNo === data.storeId && (
                  <div className="mx-1">
                    <Spinner variant="primary" size="sm" animation="border" />
                  </div>
                )
              }
              {
                callInitiateStatus === 'success' && selectedSecondaryNo === data.storeId && (
                  <div className="text-success">Call Initiate</div>
                )
              }
              {
                callInitiateStatus === 'error' && selectedSecondaryNo === data.storeId && (
                  <div className="text-danger">Oops Retry</div>
                )
              }
            </>
          ) : '--'
        ),
      },
      {
        key: 'bdeDetails',
        displayText: 'BDE Code',
        renderer: (data) => (
          data?.bdeDetails?.number ? (
            <>
              <Button
                variant="link"
                className="p-0 fs-01"
                onClick={() => {
                  this.initiateCall(data.bdeDetails.number, data.storeId, 'selectedBde');
                }}
                disabled={callInitiateStatus === 'loading'}
              >
                {data.bdeDetails.name}
              </Button>
              {
                callInitiateStatus === 'loading' && selectedBde === data.storeId && (
                  <div className="mx-1">
                    <Spinner variant="primary" size="sm" animation="border" />
                  </div>
                )
              }
              {
                callInitiateStatus === 'success' && selectedBde === data.storeId && (
                  <div className="text-success">Call Initiate</div>
                )
              }
              {
                callInitiateStatus === 'error' && selectedBde === data.storeId && (
                  <div className="text-danger">Oops Retry</div>
                )
              }
            </>
          ) : '--'
        ),
      },
      {
        key: 'finalDisposition',
        displayText: 'Prev Disposition',
      },
    ];

    return (
      <>
        <CustomModal
          show={show}
          size="xl"
          onHide={onCancel}
          body={(
            <Container
              className=""
            >
              <Row className="mb-4 pl-4 py-2 bg-primary text-white">
                <b>{`REROUTING ORDER - ${orderId}`}</b>
              </Row>
              {
                tab === 'LOADING' && (
                  <div
                    className="h-100 d-flex align-items-center
                        justify-content-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                tab === 'ERROR' && (
                  <div
                    className="justify-content-center
                    align-items-center text-danger d-flex"
                  >
                    Oops! Error while fetching stores.
                    <Button
                      variant="link"
                      onClick={() => { this.retryCall(); }}
                    >
                      <b>
                        Retry
                      </b>
                    </Button>
                  </div>
                )
              }
              {
                tab === 'STORE' && stores && (
                  <Row>
                    <Col xs={24} className="pb-4">
                      <Col>
                        <Row>
                          {
                            stores.isPickup && (
                              <Col xs="auto" className="d-flex align-items-center pr-1">
                                <div className="ml-1">
                                  <b>Distance From Store:- </b>
                                  {(stores.originalStoreDistance / 1000).toFixed(2)}
                                  &nbsp;
                                  km
                                </div>
                              </Col>
                            )
                          }
                        </Row>
                      </Col>
                    </Col>
                    <Col xs={24} className="pb-3">
                      <Row>
                        <Col lg={8} xs={24}>
                          <input
                            className="p-2 form-control fs-0 text-primary"
                            value={searchText}
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                searchText: e.target.value,
                              });
                            }}
                            placeholder="Search Shop by Shop EP Code, Phone Number"
                            autoComplete="off"
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                this.loadStores();
                              }
                            }}
                          />
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          <div className="d-flex">
                            <div
                              className="store-image-container"
                            >
                              <img
                                src="/images/premium.png"
                                className="product-image"
                                alt=""
                              />
                            </div>
                            - Premium Stores
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24}>Serviceable stores (Same Category): </Col>
                    <Col xs={24}>
                      <CustomTable
                        isPaginated={false}
                        headers={storesConfig}
                        content={this.getSameCatStores(true)}
                        totalItems={stores.count}
                        keyField="storeId"
                      />
                    </Col>
                    <Col xs={24}>Serviceable stores (Different Category): </Col>
                    <Col xs={24}>
                      <CustomTable
                        isPaginated={false}
                        headers={storesConfig}
                        content={this.getSameCatStores(false)}
                        totalItems={stores.count}
                        keyField="storeId"
                      />
                    </Col>
                    <Col xs={24} className="py-4">
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <Button
                            variant="outline-primary"
                            className="mx-2"
                            onClick={onCancel}
                          >
                            CLOSE
                          </Button>
                        </Col>
                        <Col>
                          {(() => {
                            let showcase = null;
                            if (performingAction === 'finalDisposition') {
                              showcase = (
                                <div
                                  className="pt-1 d-flex align-item-center
                                  justify-content-center"
                                >
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                    size="sm"
                                  />
                                </div>
                              );
                            } else {
                              showcase = (
                                <select
                                  onChange={(e) => {
                                    if (e.target.value === 'other') {
                                      this.setState({
                                        otherCommentModal: true,
                                      });
                                      return;
                                    }
                                    if (e.target.value !== '') {
                                      this.addFinalDisposition(e.target.value, 'finalDisposition');
                                    }
                                  }}
                                  value={stores.finalDisposition}
                                  className={`p-2 ${performingAction ? 'pointer-event-none' : ''}`}
                                >
                                  <option value="">Select</option>
                                  {finalDispositions.map((disposition) => (
                                    <option value={disposition.value} key={disposition.value}>
                                      {disposition.label}
                                    </option>
                                  ))}
                                </select>
                              );
                            }
                            return showcase;
                          })()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }
              {
                tab === 'PRODUCTS' && selectedStore && (
                  <ProductMatches
                    orderId={orderId}
                    storeCode={selectedStore.storeId}
                    onConfirmProducts={this.onConfirmProducts}
                    onCancel={() => {
                      this.setState({
                        tab: 'STORE',
                        selectedStore: null,
                      });
                    }}
                  />
                )
              }
              {
                tab === 'CONFIRMATION' && (
                  <ConfirmProducts
                    confirmedProducts={confirmedProducts}
                    prevCustomProducts={prevCustomProducts}
                    customProducts={customProducts}
                    newProducts={newProducts}
                    onCancel={() => { this.alterTab('PRODUCTS'); }}
                    onConfirm={this.getBillDetails}
                    onReroute={this.rerouteOrder}
                    rerouting={rerouting}
                    rerouteOrderError={rerouteOrderError}
                    orderConfirm={orderConfirm}
                    orderConfirmData={orderConfirmData}
                    orderConfirmClose={() => {
                      this.setState({
                        tab: 'STORE',
                        selectedStore: null,
                        orderConfirm: false,
                      });
                    }}
                    onModalClose={() => {
                      this.setState({
                        orderConfirm: false,
                      });
                    }}
                  />
                )
              }
            </Container>
          )}
        />
        <CustomModal
          show={!!updateStoreErrorMsg}
          onHide={() => this.setState({
            updateStoreErrorMsg: '',
            otherComment: '',
            otherCommentId: '',
          })}
          autoSize
          backdrop
          border
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateStoreErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={() => {
                    if (otherCommentId) {
                      this.retryPatchStore();
                    } else {
                      this.retryFinalDisposition();
                    }
                  }}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateStoreErrorMsg: '',
                      performingAction: '',
                      otherComment: '',
                      otherCommentId: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={otherCommentModal}
          onHide={() => {
            this.setState({
              otherCommentModal: false,
              otherComment: '',
              otherCommentId: '',
            });
          }}
          autoSize
          border
          body={(
            <Container className="p-4">
              <Row>
                <Col xs={24} className="py-2">Comment:</Col>
                <Col xs={24} className="py-2">
                  <input
                    type="text"
                    name="otherComment"
                    maxLength={1000}
                    className="input-box"
                    onChange={(e) => { this.setState({ otherComment: e.target.value }); }}
                    value={otherComment}
                  />
                </Col>
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="outline-primary"
                  className="font-weight-bold"
                  onClick={() => {
                    if (otherCommentId) {
                      this.patchStoreDetails(otherComment, otherCommentId, 'disposition');
                    } else {
                      this.addFinalDisposition(otherComment, 'finalDisposition');
                    }
                  }}
                  disabled={
                    !otherComment || performingAction === 'disposition'
                    || performingAction === 'finalDisposition'
                  }
                >
                  ADD COMMENT
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-black"
                  onClick={() => this.setState({
                    otherCommentModal: false,
                    otherComment: '',
                    otherCommentId: '',
                  })}
                >
                  NOT NOW
                </Button>
              </Row>
            </Container>
          )}
        />
      </>
    );
  }
}

RerouteOrder.propTypes = {
  show: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccessfulReroute: PropTypes.func.isRequired,
  orderStoreType: PropTypes.string.isRequired,
};

export default RerouteOrder;
